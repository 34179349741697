<script setup lang="ts">
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
/********************
 * PROPS & EMITS    *
 ********************/
export interface CiFAQAccordionProps {
  fields: any;
  language?: string | undefined;
}
const props = withDefaults(defineProps<CiFAQAccordionProps>(), {
  language: undefined,
});

/********************
 * COMPOSITIONS      *
 ********************/
const jsonLdStore = useJsonLdStore();
const { delegateLinksToRouter } = useDelegateToRouter();

/********************
 * INITIALIZATION    *
 ********************/
// faqs will only be added on SSR
if (import.meta.server) {
  const items = [];
  if (props.fields?.content) {
    props.fields.content.forEach((content) => {
      items.push({
        '@type': 'Question',
        'name': content.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': content.answer,
        },
      });
    });
  }
  jsonLdStore.addFaqs({ items });
}

/********************
 * HOOKS             *
 ********************/
onBeforeUnmount(() => {
  jsonLdStore.removeFaqs();
});
</script>

<template>
  <div
    :id="props.fields.anchor_id"
    :lang="props.language"
    class="section section--faq-accordion container my-12 pb-4"
  >
    <div class="row">
      <div class="col-12">
        <h2
          v-if="props.fields.headline"
          class="m-0 mb-4 text-center"
          v-html="props.fields.headline"
        />

        <template v-for="(content, index) in props.fields.content" :key="index">
          <details class="details">
            <summary class="accordion__header flex flex-row gap-[30px] px-[15px]">
              <h5 class="m-0 py-6 text-black max-md:text-base max-md:font-medium max-md:leading-6" v-html="content.question" />
              <CiAwesomeIcon
                :icon="faChevronDown"
                class="accordion__icon ml-auto self-center fill-primary transition-transform duration-[250ms] ease-in"
                ratio="0.75"
              />
            </summary>
            <div class="container pb-2">
              <div class="row">
                <div
                  class="col-12 col-lg-10 text-black"
                  @click="delegateLinksToRouter"
                  v-html="content.answer"
                />
              </div>
            </div>
          </details>
        </template>
      </div>
    </div>
  </div>
</template>

<style></style>
